.view-diffview {
  .block.accordion
    .section.full-width
    .card
    .card-body
    .accordion-item
    .accordion-content {
    max-height: none;
    padding-left: 0px;
  }
}
