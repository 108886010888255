.documento-qualita-page {
  a.button {
    margin: 10px auto;
    background-color: $tertiary;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    padding: 8px 24px;
    text-decoration: none;

    &:hover {
      background-color: darken($tertiary, 5%);
    }
  }

  .attachments-table {
    width: 70%;
    margin-left: 0px;
    padding: 15px 25px;

    li.download-item:not(:last-child) {
      border-bottom: 1px solid #c5c5c5;
      .download-button {
        display: flex;
        flex-direction: column;

        .attachment-filename {
          margin-bottom: 0.4em;
          font-size: 0.6rem;
          text-align: center;
        }

        .attachment-link {
          text-align: center;
        }
      }
    }
  }
}
