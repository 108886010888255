.block.full-width {
  .public-ui.searchSections {
    .searchSections-background {
      background-color: rgba(63, 65, 66, 0.37);
    }
    div.container {
      .searchContainer.d-flex.w-100 {
        .buttonsContainer.mt-2.d-flex {
          button.btn.btn-primary.btn-sm {
            background-color: rgba(255, 255, 255, 0.77);
            color: $primary;
            font-size: 18px;
            line-height: 27px;
          }
          button.btn.btn-primary.btn-sm:hover {
            background-color: $primary-button-color;
          }
        }
      }
    }
  }
}

.public-ui .btn.btn-tertiary {
  background-color: $tertiary-button-color;
  color: #3f4142 !important;

  svg {
    fill: #3f4142;
  }
}

.public-ui .btn-tertiary:hover {
  background-color: lighten($color: $tertiary-button-color, $amount: 5%);
  color: #3f4142 !important;

  svg {
    fill: #3f4142;
  }
}

.block.highlitedContent {
  .card-body {
    .card-title {
      a {
        color: $primary;
        font-size: 40px;
        line-height: 48px;
      }
    }

    p.card-text {
      font-family: 'Titillium Web';
      font-size: 18px;
      line-height: 28px;
      color: $body-text-color;
    }

    a.read-more {
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.29px;
      text-decoration: none;
      color: $secondary;

      svg {
        width: 26px;
        height: 26px;
        fill: $secondary;
      }
    }
  }
}

.block {
  .argumentInEvidence {
    .card {
      .card-body {
        h3.card-title {
          @include mobile(768px, 1023px) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.sliderTemplate {
  .slider-container {
    .slick-slide {
      .slide-wrapper {
        .slide-title {
          background-color: transparentize($secondary, 0.3);
          font-size: 23px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }
  }
}

.contenttype-patologia,
.contenttype-biblioteca {
  .content-area {
    #page-document {
      @include mobile(320px, 767px) {
        h1.documentFirstHeading {
          padding: 0em 0.5em;
        }
        p {
          padding: 0em 1.1em;
        }
      }
      @include mobile(768px, 1280px) {
        h1.documentFirstHeading {
          padding: 0em 0.5em;
        }
        p {
          padding: 0em 1.5em;
        }
      }
    }
  }
}
