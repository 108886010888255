.public-ui {
  .it-header-wrapper {
    .it-header-slim-wrapper {
      background-color: #01375d;
      .it-header-slim-wrapper-content {
        a.navbar-brand {
          margin-left: 24px;

          @media (min-width: map-get($map: $grid-breakpoints, $key: 'xs')) and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
            margin-left: 2.3rem;
          }
          @media (min-width: map-get($map: $grid-breakpoints, $key: 'md')) and (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
            margin-left: 2.33rem;
          }
          span {
            display: flex;
            align-items: center;

            .parent-site-logo {
              display: flex !important;
              align-items: center;
            }
          }
        }

        a.btn-icon:hover {
          color: $tertiary-button-color;
        }

        // user menu
        .it-header-slim-right-zone {
          &.it-header-slim-right-zone {
            flex: 0 0 auto;
          }
          .dropdown-menu {
            width: max-content;
          }
        }
      }
    }
  }
}
