@mixin mobile($min-size, $max-size) {
  @media only screen and (min-width: $min-size) and (max-width: $max-size) {
    @content;
  }
}

.patologie-page-container {
  // width: 100%;

  .patologie-page {
    .page-header {
      padding: 1.67em 0em 3.05em;
      margin-bottom: 4.17em;

      .row {
        @include mobile(320px, 1023px) {
          row-gap: 2em;
        }
        .title-description-wrapper {
          .search-box-group {
            display: flex;
            align-items: center;
            border: 1px solid #e7e7e7;
            padding: 0em 1.12em;
            margin-top: 5em;

            .input-group-icon {
              margin: 0em;
              padding: 0em;

              svg {
                font-size: 0.95rem;
                filter: invert(0.8);
                margin: 0em 1.12em 0em 0em;
              }
            }

            input {
              font-size: 0.8rem;
              line-height: 1.17rem;
              font-weight: 400;
              border-bottom: none;
              font-family: 'Titillium Web';
              height: 2.7rem;
            }
          }
        }
      }
    }
    .box-shadow {
      position: relative;
      background-color: #fff;
    }
    .box-shadow:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.2rem;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      z-index: -1;
    }
  }

  .categories-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.67em;

    .categories-section-title {
      flex-basis: 100%;
    }

    .categories-section-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0.83em;

      .category-button-container {
        display: flex;
        flex-wrap: nowrap;
        flex-basis: 30%;

        @include mobile(320px, 1023px) {
          flex-basis: 47%;
          width: 47%;
          align-items: baseline;
        }

        .button {
          padding: 0.8em 1em;
          align-items: center;
          background-color: #fff;
          border: solid 1px #eceff1;
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
          margin-top: 0.83em;
          font-size: 1rem;
          line-height: 1.56rem;
          font-weight: 600;
          color: #455a64;
          width: fill;

          @include mobile(320px, 430px) {
            height: 5.56rem;
            padding: 0.6em 0.6em;
          }

          @include mobile(430px, 1023px) {
            height: 5rem;
          }

          .category-button-content {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .category-button-icon-container {
              border-radius: 50%;
              background-color: #d6d6d6;
              min-width: 2.67rem;
              height: 2.67rem;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 0.95em;

              @include mobile(320px, 430px) {
                margin-right: 0.27em;
                min-width: 1.95rem;
                height: 1.95rem;
              }

              svg {
                color: $secondary;
                width: 1rem;
                height: 1rem;

                @include mobile(320px, 430px) {
                  font-size: 0.95rem;
                }
              }
            }

            .category-button-text {
              flex-basis: 85%;
              text-align: left;
            }
          }
        }

        .button:hover,
        .category-button-active {
          background-color: rgba(0, 57, 97, 0.07);
          cursor: pointer;
          color: $secondary;
        }

        .button:focus {
          outline: 1px solid $secondary;
        }
      }
    }
  }

  .navbar-search-container {
    margin-top: 3.3em;
  }

  .search-result-container {
    padding: 3em 0em 8.2em;

    .clear-button-container {
      margin: -1.1em 0em 1.6em;

      .clear-button {
        display: inline-block;
        font-weight: 600;
        line-height: 1.5;
        padding: 0.67em 1.3em;
        font-size: 0.8rem;
        white-space: initial;
        text-decoration: none;
        vertical-align: middle;
        text-align: center;
        border-radius: 4px;
        border: 0 solid $black;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: $white;
        background-color: $tertiary;
        border-color: $tertiary;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
          0 1px 1px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
