.result-card-container {
  margin-bottom: 1.95em;
  width: 100%;
  .card-container.row {
    row-gap: 1.67em;

    .single-card-container {
      &.small-card {
        flex: 1 0 29.3333%;
        @include mobile(320px, 575px) {
          flex: auto;
        }

        @include mobile(576px, 767px) {
          flex: 1 0 47%;
        }

        @include mobile(768px, 1023px) {
          flex: 1 0 47%;
        }
      }

      .card {
        height: fit-content;
        border: 1px solid #eceff1;
        border-radius: 4px;
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
        padding: 1.67em 1.4em 0.95em;
        height: 16.1rem;

        &.card-persona {
          height: fit-content;

          @include mobile(375px, 767px) {
            padding: 0px;
          }

          .person-card-content {
            display: flex;
            flex-wrap: wrap;

            @include mobile(375px, 767px) {
              flex-direction: column;
            }

            .card-content-img {
              @include mobile(375px, 767px) {
                margin-bottom: 10px;
                height: 300px;
              }

              @media screen and (min-width: 768px) {
                height: 9.38rem;
              }
              picture {
                @media screen and (min-width: 768px) {
                  width: 90%;
                  height: 100%;
                  overflow: hidden;
                  position: relative;
                }
                @media screen and (max-width: 768px) {
                  width: auto;
                  height: 18rem;
                  overflow: hidden;
                  position: relative;
                }

                img.listing-image {
                  object-fit: cover;
                  height: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }

              .no-img-container {
                width: 90%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary-a0;

                @include mobile(375px, 767px) {
                  height: 18rem;
                }

                svg {
                  width: 45%;
                  height: auto;
                  fill: $primary;
                }
              }
            }

            .card-content-text {
              @include mobile(375px, 767px) {
                margin-bottom: 1rem;
              }

              .card-text .label {
                font-weight: 600;
              }
            }

            .person-card-link-more {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              row-gap: 10px;

              .link-more-label {
                font-size: 0.8rem;
                letter-spacing: 0.05rem;
                font-weight: 600;
              }

              .btn-container {
                width: 100%;
                .book-link.btn {
                  padding: 6px 12px;
                  line-height: 1rem;
                  width: 100%;
                }
              }
            }
          }
        }

        @include mobile(320px, 1280px) {
          height: fit-content;
        }

        @include mobile(576px, 767px) {
          margin: auto;
          height: fit-content;
        }
        @include mobile(768px, 1023px) {
          margin: auto;
          height: 16.1rem;
        }

        h4 {
          font-size: 24px;
          line-height: 28px;
          color: $black;

          a {
            text-decoration: none;
            font-weight: 700;
          }

          @include mobile(768px, 1023px) {
            font-size: 1.16rem;
            line-height: 1.3rem;
          }
        }

        p {
          font-family: 'Lora';
          font-size: 0.8rem;
          line-height: 1.3rem;
          color: $black;
          margin-top: 1.3rem;
          padding-top: 0em;
          margin-bottom: 0em;
          @include mobile(768px, 1023px) {
            font-size: 0.83rem;
            line-height: 1.22rem;
            margin-top: 1em;
          }
        }

        a.read-more {
          text-transform: uppercase;
          text-decoration: none;
          font-size: 1rem;
          font-weight: bold;
          @include rem-size(line-height, 27);
          letter-spacing: 1.29px;
          color: $secondary;
          @include margin-size(margin-top, 25);

          @include mobile(768px, 1023px) {
            @include rem-size(line-height, 22);
            @include margin-size(margin-top, 15);
          }
        }

        a:hover {
          text-decoration: underline;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
