.contenttype-patologia,
.contenttype-prestazione {
  .header-infos {
    font-size: 0.89rem;
    a {
      color: $secondary;

      svg {
        height: 1.1rem;
        fill: $secondary;
      }
    }
  }
}
