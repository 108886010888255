.contenttype-unitaorganizzativa {
  #persone-struttura {
    .persone-table {
      .row {
        &:not(:last-child) {
          border-bottom: 1px solid #b8b8b8;
        }
        .col {
          display: flex;
          flex-wrap: wrap;
          &.profilo {
            padding: 10px;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.05);
          }

          &.name {
            a {
              color: $primary;
              font-weight: 600;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .row.profiles {
            width: 100%;
            padding: 10px;
            margin-right: 0px;
            margin-left: 0px;

            .profile-col {
              width: 100%;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #b8b8b8;
            }
          }
        }

        @media (min-width: map-get($map: $grid-breakpoints, $key: 'xs')) and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
          &:not(:last-child) {
            border-bottom: none;
          }

          .col.profilo {
            flex-basis: 100%;
          }
        }
      }
    }
  }
}
