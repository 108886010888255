.bandi-search {
  .container {
    .preview-image-container {
      width: 100vw;
      height: 100vw;
      position: fixed;
      z-index: 999;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.54);

      picture.volto-image {
        width: 50%;
        margin: auto;
        position: fixed;
        top: 10vh;
        left: 25vw;
        border: 10px solid white;
      }

      svg {
        position: relative;
        left: 75.8vw;
        top: 2.78em;
        font-size: 1.22rem;
        color: $white;
      }
    }
    .rounded.container {
      background: $secondary;
      .d-flex.justify-content-center {
        .d-flex.search-container.align-items-center.justify-content-center.flex-wrap {
          form.search-form {
            display: flex;
            align-items: center;
            z-index: 99;
            .search-box-container {
              flex-basis: 50%;
              .filter-wrapper {
                input {
                  width: 27.2rem;
                  border: 1px solid #d6d6d6;
                  border-radius: 4px;
                  font-size: 0.8rem;
                  line-height: 1.167rem;
                  padding: 0.67em 0em 0.83em 1.3em;
                }
              }
            }

            .select-box-container {
              margin-left: 1.167em;
              display: flex;
              justify-content: center;
              .filter-wrapper {
                .DateRangePicker {
                  .DateRangePickerInput {
                    display: flex;
                    align-items: center;
                    .DateInput {
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }

            .search-button-container {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-basis: 15%;
              button {
                background-color: $tertiary-button-color;
                color: lighten($body-text-color, 0.2);
                font-size: 1rem;
                line-height: 1.1rem;
                padding: 0.56em 1.95em;
              }
            }
          }
        }
      }
    }

    .results-container.container {
      margin-top: 2.5em;

      .slideshow-neonati-container {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 1.1em 2.05em;
        .slider-wrapper {
          position: relative;

          .autoplay-button {
            position: absolute;
            right: 0em;
            width: 4.78rem;
            height: 2rem;
            border-radius: 16px;
            border: none;
            background-color: rgba(0, 57, 97, 0.07);
            color: #004372;
            font-size: 0.8rem;
            line-height: 1.3rem;
            font-weight: bold;
            z-index: 9;

            .autoplay-button-icon {
              margin-right: 5px;
              fill: #004372;
              font-size: 1.1rem;
              line-height: 1.167rem;
            }
          }

          .larger-slider {
            button.slick-arrow.slick-prev {
              left: 0em;
            }

            button.slick-arrow.slick-next {
              right: 0em;
            }

            button.slick-arrow.slick-prev:before,
            button.slick-arrow.slick-next:before {
              color: #004372;
              opacity: 1;
              font-size: 1.68rem;
            }

            .slick-list {
              .slick-track {
                .slick-slide {
                  div {
                    display: flex !important;
                    justify-content: center;
                    flex-wrap: wrap;
                    position: relative;
                    picture.volto-image {
                      width: 60%;

                      .volto-image.responsive img {
                        margin: auto;
                      }
                    }

                    .picture-description-container {
                      flex-basis: 100%;
                      text-align: center;
                      margin: 0.56em auto 3em;

                      p {
                        font-size: 0.8rem;
                        line-height: 1.3rem;
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }

          .preview-slider {
            margin-top: -1.1rem;
            .slick-list {
              .slick-track {
                display: flex;
                justify-content: space-evenly;
                .slick-slide,
                .slick-slide.slick-active,
                .slick-slide.slick-active.slick-current {
                  width: fit-content !important;

                  .volto-image.responsive img {
                    height: 4.5rem;
                    width: 6.5rem;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }

      .activate-table-section {
        margin-top: 2.61em;
        text-align: right;
        a {
          text-transform: uppercase;
          text-decoration: none;
          font-size: 0.78rem;
          line-height: 1.167rem;
          font-weight: 600;
          letter-spacing: 1px;
          color: $secondary;

          svg {
            margin-right: 0.278em;
            margin-bottom: 0.2em;
          }
        }

        a:hover {
          color: $primary;
        }
      }
    }

    .tabella-neonati-container {
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 0em;
      table {
        thead {
          th {
            p {
              margin: 0em;
              font-size: 0.8rem;
              line-height: 1.3rem;
            }
          }
        }
        tbody {
          tr {
            td.px-5,
            td.px-4 {
              vertical-align: baseline;
              p {
                margin: 0em;
                font-size: 0.8rem;
                line-height: 1.3rem;
                color: #272727;
              }

              button {
                background-color: $tertiary;
                border: none;
                border-radius: 4px;
                font-size: 0.72rem;
                font-weight: 600;
                line-height: 1.1rem;
                color: white;
                padding: 0.4em 1.1em;
              }
            }
          }
        }
      }
    }

    .pagination-container {
      margin: 2.78em auto 0em;
      display: flex;
      display: flex;
      justify-content: left;
      align-items: baseline;
      .activate-slideshow-section {
        a {
          text-transform: uppercase;
          text-decoration: none;
          font-size: 0.78rem;
          line-height: 1.167rem;
          font-weight: 600;
          letter-spacing: 1px;
          color: $secondary;

          svg {
            margin-right: 0.278em;
            margin-bottom: 0.22em;
          }
        }

        a:hover {
          color: $primary;
        }
      }

      .pagination-section {
        display: flex;
        align-items: baseline;
        justify-content: center;
        flex-basis: 80%;
      }
    }
  }
}
