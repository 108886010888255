.custom-form {
  .field {
    label,
    .form-group .bootstrap-checkbox-radio-wrapper label.field-title-label {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $body-text-color;
      padding-left: 0em;
      font-weight: 700;
    }
    .form-group {
      //select field
      .form-select {
        width: 19rem;
        margin-top: 1.1em;

        @include mobile(320px, 767px) {
          width: auto;
          margin-top: 0.83em;
        }
        .react-select__control {
          border-bottom: solid 2px $primary !important;
          .react-select__value-container {
            font-size: 0.8rem;
            line-height: 1.3rem;
            padding-left: 1.39em;

            .react-select__single-value {
              color: $body-text-color;
              font-weight: 400;
              font-size: 1rem;
            }
          }
        }
      }
      //checkbox field
      .bootstrap-checkbox-radio-wrapper {
        label.checkbox-text {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: $body-text-color;
        }

        //scelta multipla field

        .checkbox-form.form-check {
          .form-group {
            margin-bottom: 0em;
            label {
              font-weight: 400;
              top: 0.2em !important;
            }
            label.active {
              transform: translateY(0);
            }
          }
        }
      }
    }

    div.text-field,
    div.text-area-field,
    div.email-field,
    div.static-text-field {
      input.form-control,
      textarea,
      .DraftEditor-root {
        background-color: #f2f2f2;
        border: none;
        border-radius: 4px;
        padding: 0.72em 0.95em;
        color: $black;
      }

      input.form-control::placeholder {
        font-size: 0.8rem;
        line-height: 1.3rem;
        color: $placeholder;
        font-weight: 400;
        font-family: $font-family-base;
      }
      input[type='text']:focus,
      input[type='email']:focus,
      textarea:focus {
        background-color: #efefef;
        border: 1px solid $primary;
      }
    }
  }
}
