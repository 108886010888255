.full-width.contact-info-container {
  background-color: $primary;
  height: fit-content;
  color: $white;
  font-family: 'Titillium Web';
  font-weight: 300;

  // container per pagina edit
  & .edit-subcontainer {
    display: flex;
    justify-content: center;
    margin: 0em auto;
    width: 75em;

    .container {
      width: 100%;
      .row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2em;
        justify-content: center;

        @media (min-width: 992px) {
          .col-lg-6 {
            flex: 0 0 45%;
            max-width: 50%;
            min-width: 45%;
          }
        }

        @media (min-width: 375px) and (max-width: 991px) {
          .col-md-12,
          .col-sm-12 {
            flex: 0 0 100%;
            max-width: 100%;
            min-width: 50%;
          }
        }
      }
    }
  }

  .external-link {
    display: none;
  }

  .contact-info-content {
    display: flex;
    flex-direction: column;

    .subsection-title-container {
      p,
      span {
        font-size: 2.22rem;
        font-family: 'Titillium Web';
        font-weight: bold;
        line-height: 2.22rem;
        color: $white;

        @include mobile(320px, 430px) {
          min-height: 1.95rem;
        }
      }
    }

    .icon-list-subsection {
      display: flex;
      flex: 0 0 59%;
      flex-wrap: wrap;
      padding-right: 1.1rem;
      min-width: 50%;

      @include mobile(320px, 424px) {
        padding-right: 0em;
      }

      .list-container {
        padding-left: 0em;
        margin-top: 3.61em;

        @include mobile(320px, 424px) {
          margin-top: 1.67em;
        }

        .list-item {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          margin-bottom: 0.83em;
          .icon-section {
            flex-basis: 10%;

            svg {
              font-size: 1.61rem;
              margin: 0em 1.39em 0em 0em;

              @include mobile(320px, 374px) {
                font-size: 1.22rem;
              }
              @include mobile(375px, 430px) {
                margin: 0em 0.83em 0em 0.56em;
              }
            }
          }

          .content-section {
            flex-basis: 88%;

            @include mobile(320px, 430px) {
              margin-right: 0.56em;
            }

            .text-section {
              flex-basis: 100%;

              p {
                margin: 0em;
                color: $white;
                font-size: 1.125rem;
                line-height: 1.555;
                font-weight: 300;

                @include mobile(320px, 374px) {
                  font-size: 0.8rem;
                }
              }
            }

            .link-section {
              flex-basis: 100%;

              a {
                text-decoration: none;
                color: $white;
                display: flex;
                align-items: center;

                @include mobile(375px, 767px) {
                  font-size: 1.125rem;
                }

                svg.icon.arrow-icon.icon-white {
                  height: 1.67rem;
                  margin: 0em 0em 0em;
                  fill: $white;
                }

                svg.icon.external-link {
                  fill: $white !important;
                }
              }
            }
          }
        }
      }
    }

    .contact-card-subsection {
      flex-basis: 40%;

      .card-container {
        background-color: $white;
        padding: 1.39em 1.67em;
        margin-top: 1.67em;
        border: 1px solid #eceff1;
        border-radius: 4px;

        @include mobile(320px, 430px) {
          margin-top: 0.56em;
        }

        .card-content {
          .text-content {
            color: $black;
            margin-bottom: 1.1em;
            h4 {
              font-size: 1.5rem;
              line-height: 1.89rem;
            }

            p {
              font-size: 1rem;
              line-height: 1.3rem;
            }
          }

          .contact-info-grid {
            margin: 0em auto 0.83em;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .contact-grid-icon {
              margin-right: 0.83em;
              min-width: 1.875rem;

              svg.icon.contact-icon {
                height: 1.67rem;
                width: auto;
                fill: #505265;

                @include mobile(320px, 430px) {
                  height: 1.3rem;
                }
              }
            }

            .contact-grid-text {
              p {
                margin: 0em;
                color: #3c3c3c;
                font-size: 1.78rem;
                line-height: 2.67rem;

                @include mobile(320px, 374px) {
                  font-size: 1.1rem;
                }
                @include mobile(375px, 430px) {
                  font-size: 1.3rem;
                }
              }

              a {
                font-size: 1.3rem;
                line-height: 2rem;

                @include mobile(320px, 374px) {
                  font-size: 1.1rem;
                }

                @include mobile(375px, 430px) {
                  font-size: 1.22rem;
                }
              }
            }
          }

          .link-section {
            position: relative;
            width: -moz-fit-content;
            width: fit-content;

            a {
              color: $secondary;
              display: flex;
              align-items: center;

              @include mobile(320px, 374px) {
                font-size: 1rem;
              }
            }

            svg.arrow-icon {
              margin: -0.167em 0em 0em;
              fill: $secondary;
              height: 1.78rem;

              @include mobile(320px, 374px) {
                height: 1.1rem;
                margin-left: 0.278em;
              }
            }
          }
        }
      }
    }
  }
}
